<template>
  <div>
    <h4 class="pb-4">Top Consumptions (last 7 days)</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Account No</th>
          <th scope="col">Consumer Name</th>
          <th scope="col">Meter Number</th>
          <th scope="col">Reading Date Time</th>
          <th scope="col">Consumptions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(topConsumption, index) in topConsumptions" :key="topConsumption.id">
          <td>
            <router-link :to="'/consumers/' +  topConsumption.customer_id"> {{topConsumption.customer_account && topConsumption.customer_account.account_number}}</router-link>
          </td>
          <td> {{ topConsumption.customer_account && topConsumption.customer_account.first_name }}</td>
          <td>
            <router-link :to="'/meters/' +  topConsumption.meter_id">
              {{ topConsumption.meter_number }}
            </router-link>
          </td>
          <td>{{ topConsumption.reading_date_time | dateTimeFormat }}</td>
          <td>{{ topConsumption.water_unit }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("Dashboard", ["topConsumptions"]),
  },
  mounted() {
    this.$store.dispatch("Dashboard/getTopConsumptions");
  },
};
</script>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button {
      margin-top: 20px;
    }
  }
}
</style>
