<template>
  <div>
    <h4 class="pb-4">Top Due</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Consumer Name</th>
            <th scope="col">Account Number</th>
            <th scope="col">Due Bill</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="due in topDueBills" :key="due.custoner_account_id">
            <td> <router-link :to="'/customer-accounts/' +  due.customer_account_id"> {{ due.customer_name }}</router-link> </td>
            <td>{{ due.account_number }}</td>
            <td>{{ due.total_amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <router-link to="/bills">
      <button type="button" class="btn btn-primary float-right view-all">
        View all
      </button></router-link
    >
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("Bill", ["topDueBills"]),
  },
  mounted() {
    this.$store.dispatch("Bill/getTopDueBills");
  },
};
</script>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button {
      margin-top: 20px;
    }
  }
}
</style>


<style>
.view-all {
  font-size: 12px !important;
  border-radius: 2px !important;
}
</style>
